import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UserService from "@/core/services/user.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_GETTING_TOKEN_REQUEST = "setSettingTokenRequest";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  gettingTokenRequest: false,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("training/login", credentials, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: window.localStorage.getItem('ttoken'),
        },
      })
          .then( ({data})  => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
          });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    console.log(context);
    /*
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
    */
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    if(data.api_token != null) {
      state.isAuthenticated = true;
      state.user = data.user;
      state.errors = {};
      JwtService.saveToken(data.api_token);
      UserService.saveUserInfo(data.user)
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    UserService.destroyUserInfo();
  },
  [SET_GETTING_TOKEN_REQUEST](state, value) {
    state.gettingTokenRequest = value
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
