import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/help",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/help",
          name: "help",
          component: () => import("@/view/pages/Help.vue"),
        },
        {
          path: "/trainings",
          name: "trainings",
          component: () => import("@/view/modules/trainings/Index.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/modules/profile/Profile.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
