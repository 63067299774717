// action types
import ApiService from "@core/services/api.service";

export const GET_ORDERS_LIST = "getOrdersList";

// mutation types
export const SET_ORDERS_LIST = "setOrdersList";
export const SET_ORDER_SELECTED = "setOrderSelected";
export const SET_ORDERS_LIST_TOTAL = "setOrdersListTotal";
export const ORDER_SET_COURSES_LIST = "setOrdersCourseList";
export const SEND_CONFIRMATION_COURSES = "sendConfirmationCourses";

const state = {
  ordersListTotal: 2,
  orderSelected: null,
  ordersList: [],
  coursesList: [],
};

const getters = {};

const actions = {
  [GET_ORDERS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/student/training?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage + "&search=" + filters.search
      )
          .then( ({data}) => {
            console.log(data)
            context.commit(SET_ORDERS_LIST, data.data);
            context.commit(SET_ORDERS_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [SEND_CONFIRMATION_COURSES](context, filters) {
    return new Promise((resolve) => {
      ApiService.post(
          "edu-training/student/course_selection", filters
      )
          .then( data => {
            resolve(data);
          })
          .catch( error => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
};

const mutations = {
  [SET_ORDERS_LIST](state, value) {
    state.ordersList = value;
  },
  [SET_ORDERS_LIST_TOTAL](state, value) {
    state.ordersListTotal = value;
  },
  [SET_ORDER_SELECTED](state, value) {
    state.orderSelected = value;
  },
  [ORDER_SET_COURSES_LIST](state, value) {
    state.coursesList = value
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
