import Vue from "vue";
import axios from "axios";
import store from "@store";
import router from "@router";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import FileSaver from "file-saver";

import { SET_GETTING_TOKEN_REQUEST } from "@/store/auth.module";

// Global variables
const appBaseUrl = process.env.VUE_APP_BASE_URL
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
const clientId = process.env.VUE_APP_CLIENT_ID
const clientSecret = process.env.VUE_APP_CLIENT_SECRET
const grantType = process.env.VUE_APP_GRANT_TYPE

// Add authorization token to all request
axios.interceptors.request.use(config => {
    const token = JwtService.getToken()
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers.Accept = 'application/json'
    return config
}, error => Promise.reject(error))

// Check all responses in order to handle authorization error
axios.interceptors.response.use(response => response, error => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
        if (!store.state.auth.gettingTokenRequest) {
            store.commit(SET_GETTING_TOKEN_REQUEST, true)
            originalRequest._retry = true
            return axios.post(`${appBaseUrl}oauth/token`, {
                client_id: clientId,
                client_secret: clientSecret,
                scope: '*',
                grant_type: grantType
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }).then(res => {
                store.commit(SET_GETTING_TOKEN_REQUEST, false)
                if (res.status === 201 || res.status === 200) {
                    window.localStorage.removeItem('ttoken')
                    window.localStorage.setItem('ttoken', res.data.access_token)
                }else{
                    router.push('login')
                }
                let tokenToCheck = "ttoken"
                if(originalRequest.url !== "training/login"){
                    tokenToCheck = "utoken"
                }
                if(window.localStorage.getItem(tokenToCheck) != null) {
                    originalRequest.headers.Authorization =
                        "Bearer " + window.localStorage.getItem(tokenToCheck);
                }else{
                    router.push("login")
                }

                return axios(originalRequest)
            })
        }
    }
    return error
})

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = apiBaseUrl;
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
        Vue.axios.defaults.headers.common["Strict-Transport-Security"] =
            "max-age=31536000; includeSubDomains";
        Vue.axios.defaults.headers.common["Content-Security-Policy"] =
            "default-src https";
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch((error) => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @returns {*}
     */
    get(resource) {
        return Vue.axios.get(`${resource}`).catch((error) => {
            console.log("error", error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params, config) {
        return axios
            .post(`${resource}`, params, config)
            .then((response) => response)
            .catch((error) => {
                console.log(error);
                throw error;
            });
    },

    /**
     * Set the POST HTTP request with formdata
     * @param resource
     * @param formData
     * @returns {*}
     */
    postFormdata(resource, formData) {
        Vue.axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
        return Vue.axios.post(`${resource}`, formData);
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    postBuffer(resource, params) {
        return Vue.axios.post(`${resource}`, params, {
            responseType: "arraybuffer",
        });
    },

    exportExcel(resource, params) {
        return Vue.axios
            .post(`${resource}`, params, {
                responseType: "blob",
            })
            .then((response) => {
                FileSaver.saveAs(response.data, "fichero.xlsx");
            });
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch((error) => {
            // console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    /**
     * Sen image to External Storage
     * @returns {*}
     * @param fileData
     */
    sendImage(fileData) {
        Vue.axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
        return Vue.axios.post(`file/upload/upload-specify-container`, fileData);
    },

    postGetFile(resource, params, fileName) {
        return axios({
            url: `${apiBaseUrl}api/${resource}`,
            method: 'POST',
            data: params,
            responseType: 'blob',
        }).then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
            return response
        })
    },
    getGetFile(resource, params, fileName) {
        return axios({
            url: `${apiBaseUrl}api/${resource}`,
            method: 'GET',
            data: params,
            responseType: 'blob',
        }).then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
            return response
        })
    },
};

export default ApiService;
